const logedInUser = JSON.parse(localStorage.getItem("user"));
const initialState = logedInUser
  ? {
      isLoggedIn: true,
      userRole: logedInUser.role_name,
      values: {
        ...logedInUser,
        name: [logedInUser.first_name, " ", logedInUser.first_surname],
      },
      loginError: null,
    }
  : { isLoggedIn: false, userRole: null, values: null, loginError: null };

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_JWT": {
      const { role_name } = action.payload;
      return {
        ...state,
        userRole: role_name,
        values: action.payload,
      };
    }
    case "LOGIN_FAIL": {
      return {
        ...state,
        loginError: "Usuario y/o contraseña incorrectos",
      };
    }
    case "CLEAR_LOGIN_ERROR": {
      return {
        ...state,
        loginError: null,
      };
    }
    case "LOGOUT_WITH_JWT": {
      return {
        ...state,
        userRole: "anonymous",
        isLoggedIn: false,
        values: action.payload,
      };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
