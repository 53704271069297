import React, { useContext, useEffect, useState } from "react";
import { getNotifications } from "../services/NotificationServices";
import { globalAlertContext } from "../Context/GlobalAlertContext";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export const notificationContext = React.createContext();

const NotificationsContext = ({ children }) => {
  const initialState = {
    count: 0,
    items: [],
  };
  const [notifications, setNotifications] = useState(initialState);
  const { setAlertInfo } = useContext(globalAlertContext);
  const location = useLocation();
  const userRole = useSelector((state) => state.auth.login.userRole);
  useEffect(() => {
    if (userRole === "Recursos Humanos") {
      getNotifications()
        .then(({ data }) =>
          setNotifications({ count: data.length, items: data })
        )
        .catch((err) => console.log(err));
    }
  }, [setAlertInfo, location, userRole]);
  return (
    <notificationContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </notificationContext.Provider>
  );
};

export default NotificationsContext;
