import moment from "moment";

export const docMap = {
  1: "Antecedentes Penales",
  2: "Antecedentes Policiales",
  3: "Copia de DPI",
  14: "Partida de Nacimiento",
};

const unixConvert = (date) => {
  return parseInt(Math.floor(new Date(date).getTime() / 1000).toFixed(0));
};

export const getNotificationText = ({ type, data }) => {
  const textObj = {};
  switch (type) {
    case "pending_rotation_request":
      textObj.key = `notif-rotation-${data.id}`;
      textObj.title = "Se ha solicitado una rotación";
      textObj.description = `
            Se solicita la rotación del agente ${data.full_name}
            (DPI: ${data.agent_dpi})
            al proyecto ${data.new_project_name} en el puesto 
            ${data.new_position}
        `;
      textObj.date = moment(data.created_at).format("DD/MM/YYYY");
      textObj.url = "/solicitudes-rotacion/pendientes";
      break;
    default:
      textObj.key = `notif-paperwork-${data.id}`;
      textObj.title = "Un documento se ha vencido";
      textObj.description = `
            El documento ${docMap[data.doc_type_id]}
            se ha vencido para el agente ${data.nombre_del_agente} (DPI: ${
        data.candidate_id
      })
            `;
      textObj.date = unixConvert(data.expiry_dt);
      textObj.url = `/ingresoPerfilCandidato/${data.candidate_id}`;
  }
  return textObj;
};
