import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "analyticsDash",
    title: "Inicio",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [
      "Admin",
      "Recursos Humanos",
      "Operaciones",
      "Contabilidad",
      "Perfiles",
      "Root",
      "Control de Inventario",
    ],
    navLink: "/",
  },
  {
    id: "ingresoPerfil",
    title: "Ingresar Perfil de Candidato",
    type: "item",
    permissions: ["Recursos Humanos"],
    icon: <Icon.UserPlus size={20} />,
    navLink: "/ingresoPerfilCandidato",
  },
  {
    id: "candidatosDisponibles",
    title: "Candidatos Disponibles",
    type: "item",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.Users size={20} />,
    navLink: "/candidatosDisponibles",
  },
  {
    id: "personalAdministrativo",
    title: "Personal Administrativo",
    type: "item",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.Users size={20} />,
    navLink: "/personal-administrativo",
  },
  {
    id: "tablaAgentesDeAlta",
    title: "Agentes de Alta",
    type: "item",
    permissions: ["Recursos Humanos"],
    icon: <Icon.UserCheck size={20} />,
    navLink: "/agentesDeAlta",
  },
  {
    id: "tablaAgentesDeBaja",
    title: "Solicitudes de Baja",
    type: "item",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.UserMinus size={20} />,
    navLink: "/agentesDeBaja",
  },
  {
    id: "agentesDeBajaDefinitiva",
    title: "Agentes de Baja Definitiva",
    type: "item",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.UserX size={20} />,
    navLink: "/agentesDeBajaDefinitiva",
  },
  {
    id: "rotacionPersonal",
    title: "Agentes de Alta",
    type: "item",
    permissions: ["Operaciones", "Root"],
    icon: <Icon.Users size={20} />,
    navLink: "/rotacionPersonal",
  },
  {
    id: "tablaAgentesTurnosExtras",
    title: "Turnos Extras Reportados",
    type: "item",
    permissions: ["Operaciones"],
    icon: <Icon.Clock size={20} />,
    navLink: "/tablaAgentesTurnosExtras",
  },
  {
    id: "tablaAgentesFeriados",
    title: "Feriados Reportados",
    type: "item",
    permissions: ["Operaciones"],
    icon: <Icon.Calendar size={20} />,
    navLink: "/tablaAgentesFeriados",
  },
  {
    id: "tablaDescanseros",
    title: "Descanseros",
    type: "item",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.UserCheck size={20} />,
    navLink: "/tablaDescanseros",
  },
  {
    id: "rotacionRh",
    title: "Rotaciones",
    type: "collapse",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "solicitudesRotacionPendientes",
        title: "Pendientes",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/solicitudes-rotacion/pendientes",
      },
      {
        id: "solicitudesRotacionAprobadas",
        title: "Aprobadas",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/solicitudes-rotacion/aprobadas",
      },
      {
        id: "solicitudesRotacionRechazadas",
        title: "Rechazadas",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/solicitudes-rotacion/rechazadas",
      },
    ],
  },
  {
    id: "liquidaciones",
    title: "Liquidaciones",
    type: "collapse",
    permissions: ["Recursos Humanos", "Root"],
    icon: <Icon.Calendar size={20} />,
    children: [
      {
        id: "tablaLiquidaciones",
        title: "Tabla",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/tablaLiquidaciones",
      },
      {
        id: "reporteLiquidaciones",
        title: "Reporte",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/reporteLiquidaciones",
      },
    ],
  },
  {
    id: "vacaciones",
    title: "Vacaciones",
    type: "collapse",
    icon: <Icon.Calendar size={20} />,
    permissions: ["Recursos Humanos", "Root"],
    children: [
      {
        id: "tablaVacaciones",
        title: "Tabla",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/tablaVacaciones",
      },
      {
        id: "reporteVacaciones",
        title: "Reporte",
        type: "item",
        permissions: ["Recursos Humanos", "Root"],
        icon: <Icon.Circle size={10} />,
        navLink: "/reporteVacaciones",
      },
    ],
  },
  {
    id: "config-liq-vac",
    title: "Configurar",
    type: "item",
    icon: <Icon.Settings size={20}/>,
    permissions: ["Recursos Humanos", "Root"],
    navLink: "/liquidaciones-vacaciones-config",
  },
  {
    id: "descuentosPorFalta",
    title: "Reportar Faltas",
    type: "item",
    permissions: ["Operaciones"],
    icon: <Icon.FileMinus size={20} />,
    navLink: "/descuentosPorFalta",
  },
  {
    id: "pryectosAdmin",
    title: "Proyectos",
    type: "item",
    permissions: ["Admin", "Contabilidad", "Root"],
    icon: <Icon.Briefcase size={20} />,
    navLink: "/proyectosAdmin",
  },
  {
    id: "tablaUsuarios",
    title: "Tabla de Usuarios",
    type: "item",
    permissions: ["Perfiles", "Root"],
    icon: <Icon.Users size={20} />,
    navLink: "/tablaUsuarios",
  },
  /* TURNOS EXTRAS */
  {
    id: "Turnos Extra",
    title: "Turnos Extra",
    type: "collapse",
    permissions: ["Contabilidad", "Root"],
    icon: <Icon.Clock size={20} />,
    children: [
      {
        id: "turnosExtrasConta",
        title: "Reportados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.Circle size={15} />,
        navLink: "/turnosExtras",
      },
      {
        id: "turnosExtrasAprobados",
        title: "Aprobados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.CheckCircle size={15} />,
        navLink: "/turnosExtrasAprobados",
      },
      {
        id: "turnosExtrasRechazados",
        title: "Rechazados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.XCircle size={15} />,
        navLink: "/turnosExtrasRechazados",
      }
    ]
  },
  /* FERIADOS */
  {
    id: "feriadosMasivo",
    title: "Feriados",
    type: "collapse",
    icon: <Icon.Calendar size={20}/>,
    permissions: ["Contabilidad", "Root"],
    children: [
      {
        id: "feriadosMasivoPendiente",
        title: "Por Proyecto",
        type: "item",
        icon: <Icon.Circle size={15} />,
        permissions: ["Contabilidad" ,"Root"],
        navLink: "/feriados-masivos/pendientes"
      },
      {
        id: "feriadosConta",
        title: "Por Agente",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.Circle size={15} />,
        navLink: "/feriados",
      },
      {
        id: "feriadosAprobados",
        title: "Aprobados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.CheckCircle size={15} />,
        navLink: "/feriadosAprobados",
      },
      {
        id: "feriadosRechazados",
        title: "Rechazados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.XCircle size={15} />,
        navLink: "/feriadosRechazados",
      },
    ]
  },
  /* DESCUENTOS CONTA */
  {
    id: "descuentosConta",
    title: "Descuentos",
    type: "item",
    permissions: ["Contabilidad", "Root"],
    icon: <Icon.FileMinus size={20} />,
    navLink: "/descuentosContabilidad",
  },
  /* REPORTES CONTA */
  {
    id: "reportes",
    title: "Reportes",
    type: "collapse",
    permissions: ["Contabilidad", "Root"],
    icon: <Icon.File size={20} />,
    children: [
      {
        id: "reportesProyectos",
        title: "Por día",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteProyectos",
      },
      {
        id: "reporteGeneral",
        title: "Nómina General",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteGeneral",
      },
      {
        id: "reporteAgentes",
        title: "De Descanseros",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteAgentes",
      },
      {
        id: "reporteTurnosExtra",
        title: "De Turnos Extra",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteTurnosExtra",
      },
      {
        id: "reporteFeriados",
        title: "De Feriados",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteFeriados",
      },
      {
        id: "reporteDescuentos",
        title: "De Descuentos",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteDescuentos",
      },
      {
        id: "reporteVacantes",
        title: "De Vacantes",
        type: "item",
        permissions: ["Contabilidad", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporteVacantes",
      },
    ],
  },
  // Salarios de descanseros
  {
    id: "salariosDescanseros",
    title: "Salarios de Descanseros",
    type: "item",
    permissions: ["Contabilidad"],
    icon: <Icon.DollarSign size={20} />,
    navLink: "/salarios-descanseros",
  },
  {
    id: "reportesInventario",
    title: "Reportes",
    type: "collapse",
    permissions: ["Control de Inventario", "Root"],
    icon: <Icon.File size={20} />,
    children: [
      {
        id: "contorlInventario",
        title: "Proyectos",
        type: "item",
        permissions: ["Control de Inventario", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/contorlInventario",
      },
      {
        id: "reporteBodega",
        title: "Bodega",
        type: "item",
        permissions: ["Control de Inventario", "Root"],
        icon: <Icon.File size={20} />,
        navLink: "/reporte-bodega",
      }
    ]
  },
  {
    id: "tablaInventario",
    title: "Inventario",
    type: "item",
    permissions: ["Control de Inventario", "Root"],
    icon: <Icon.Clipboard size={20} />,
    navLink: "/tablaInventario",
  },
  {
    id: "iventarioEquipo",
    title: "Suministros",
    type: "item",
    permissions: ["Control de Inventario", "Root"],
    icon: <Icon.Clipboard size={20} />,
    navLink: "/inventarioEquipo",
  },
  {
    id: "ingresoFeriados",
    title: "Ingresar Feriados",
    type: "item",
    permissions: ["Operaciones"],
    icon: <Icon.Calendar size={20} />,
    navLink: "/ingreso-feriados-proyecto",
  },
];

export default navigationConfig;
