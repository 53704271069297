import axios from "axios";
import jwt from "jsonwebtoken";

const API_URL = `${process.env.REACT_APP_API_HOST}/auth`;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/login", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          const user = jwt.decode(response.data.accessToken);
          user.accessToken = response.data.accessToken;
          localStorage.setItem("user", JSON.stringify(user));
          return user;
        }
        throw Error(`Error when logging in user ${JSON.stringify(response)}`);
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
  // register(username, email, password) {
  //   return axios.post(API_URL + "signup", {
  //     username,
  //     email,
  //     password,
  //   });
  // }
}
export default new AuthService();
