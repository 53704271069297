import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
// import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history";
import { useDispatch } from "react-redux";
import AuthService from "../../../services/AuthService";

const UserDropdown = (props) => {
  // const { isAuthenticated } = useAuth0()
  const dispatch = useDispatch();
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={(e) => {
          e.preventDefault();
          AuthService.logout();
          dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
          history.push("/pages/login");
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Cerrar Sesión</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user"></span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
