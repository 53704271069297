import React, { useContext } from "react";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { connect, useSelector } from "react-redux";
import classnames from "classnames";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import { toggleSideBar } from "../../../redux/actions/sidebar";
import { logoutWithFirebase } from "../../../redux/actions/auth/loginActions";
import NavbarUser from "./NavbarUser";
import { Bell, Menu } from "react-feather";
import "../../../assets/scss/notification-bar.scss";
import { notificationContext } from "../../../Context/NotificationsContext";
import { getNotificationText } from "../../../utility/custom/NotificationsUtility";
import { Link } from "react-router-dom";

const UserName = (props) => {
  let username = "";
  if (props.userdata !== undefined) {
    username = props.userdata.name;
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name;
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name;
    }
  } else {
    username = "John Doe";
  }

  return username;
};
const ThemeNavbar = (props) => {
  const { user } = useAuth0();
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  // const [_, setIsNotifOpen] = useState(false);
  const { notifications } = useContext(notificationContext);
  const userRole = useSelector((state) => state.auth.login.userRole);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex"
              style={{ alignItems: "baseline", justifyContent: "end" }}
              id="navbar-mobile"
            >
              <div
                className="navbar-menu-toggle d-xl-none"
                color="primary"
                style={{ marginRight: "auto" }}
                onClick={() => props.toggleSideBar(!props.sidebar.show)}
              >
                <Menu size={21} />
              </div>
              {userRole === "Recursos Humanos" && (
                <UncontrolledButtonDropdown
                  className="dropdown-notification nav-item"
                  tag="li"
                >
                  <DropdownToggle
                    tag="a"
                    // onClick={() => setIsNotifOpen((prevState) => !prevState)}
                    data-toggle="dropdown"
                    aria-expanded={false}
                    className="nav-link nav-link-label"
                  >
                    <Bell size={21} />
                    {notifications.count > 0 && (
                      <Badge pill color="primary" className="badge-up">
                        {notifications.count}
                      </Badge>
                    )}
                  </DropdownToggle>
                  <DropdownMenu tag="ul" right className="dropdown-menu-media">
                    <li className="dropdown-menu-header">
                      <div className="dropdown-header mt-0">
                        <h3 className="text-white">
                          {notifications.count} Nuevas
                        </h3>
                        <span className="notification-title">
                          Notificaciones
                        </span>
                      </div>
                    </li>
                    <div className="notifications-container">
                      {notifications.items
                        .map((item) => getNotificationText(item))
                        .map((item) => (
                          <div
                            className="d-flex notification-wrapper"
                            key={item.key}
                          >
                            <Link to={item.url}>
                              <div className="notification-text">
                                <h6>{item.title}</h6>
                                <p>{item.description}</p>
                              </div>
                              <div className="notification-date">
                                {item.date}
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} {...props} />}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
    sidebar: state.sidebar,
  };
};

export default connect(mapStateToProps, {
  logoutWithFirebase,
  useAuth0,
  toggleSideBar,
})(ThemeNavbar);
