import axios from "axios";
import authHeader from "./DataService";
import BackEndError from "../utility/BackEndError";

const url = `${process.env.REACT_APP_API_HOST}/notificaciones?per_page=20&page=1`;

export const getNotifications = async () => {
  try {
    return await axios.get(url, {
      headers: authHeader(),
    });
  } catch (err) {
    throw new BackEndError(
      "Ha ocurrido un problema de comunicación, intente de nuevo. Si el problema persiste, contacte a soporte técnico.",
      err.response.data ? err.response.data.errors : [],
      err.response.status
    );
  }
};
