import React, { useState } from "react";

export const globalAlertContext = React.createContext();

const GlobalAlertContext = ({ children }) => {
  const alertInfoInitialState = {
    title: "Acción Exitosa",
    subtitle: null,
    show: false,
    type: "success",
    onClose: () => {},
    showCloseButton: false,
    onCancel: null
  };
  const [alertInfo, setAlertInfo] = useState(alertInfoInitialState);
  return (
    <globalAlertContext.Provider value={{ alertInfo, setAlertInfo, alertInitialState: alertInfoInitialState  }}>
      {children}
    </globalAlertContext.Provider>
  );
};

export default GlobalAlertContext;
